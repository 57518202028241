@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
}

.grid-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.grid-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.grid-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.7);
  border-radius: 20px;
  border: transparent;
}

body.create-game-page {
  overflow: auto !important;
}

.create-game-container {
  min-height: 100vh;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.create-game-container form {
  margin-bottom: 4rem;
}